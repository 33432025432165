import React from 'react'
import { Paragraph, SectionHeading, SectionSubheading } from 'styles/typography'
import { Container, SectionContainer } from 'styles/container'

import { Facebook, Instagram, Youtube } from '@styled-icons/feather'

import { SocialIcons } from './styles'

const ContactUs = () => {
  return (
    <SectionContainer id="contact-us">
      <Container>
        <SectionHeading>Contact Us</SectionHeading>
        <SectionSubheading>Bulk orders, feedback and suggestions</SectionSubheading>
        <Paragraph>We'd love to hear back from you. If you would like to make a bulk order, give us some feedback or send us a suggestion, please send an email to <a href='mailto:hello@thebeanemporium.com'>hello@thebeanemporium.com</a>.</Paragraph>
        <Paragraph><strong>Note:</strong> If you made a purchase through Takealot and would like to return the product, please process the return through takealot.com.</Paragraph>
        <SocialIcons>
          <a href='https://www.facebook.com/thebeanemporium/' target='_blank' title='Facebook'><Facebook size='3rem' /></a>
          <a href='https://www.youtube.com/channel/UC9nfYBjbXXlZtWBJXyMFacA' target='_blank' title='Youtube'><Youtube size='3rem' /></a>
        </SocialIcons>
      </Container>
    </SectionContainer>
  )
}

export default ContactUs