import React from 'react'
import { Paragraph, SectionHeading, SectionSubheading } from 'styles/typography'
import { Container, SectionContainer } from 'styles/container'
import { graphql, useStaticQuery } from 'gatsby'

import { Description, Image, ProductDetails } from './styles'
import LinkButton from 'components/link-button'

const Product = () => {
  const data = useStaticQuery(graphql`
      query ProductImageQuery {
          file(relativePath: { eq: "refillable-pods-lifestyle-1.jpg" }) {
              childImageSharp {
                  fixed(quality: 92 width: 460) {
                      ...GatsbyImageSharpFixed_withWebp
                  }
              }
          }
      }
  `)

  return (
    <SectionContainer id="refillable-coffee-pods">
      <Container>
        <SectionHeading>Refillable Coffee Pods</SectionHeading>
        <SectionSubheading>Nespresso&trade; compatible, refillable and reusable.</SectionSubheading>
        <ProductDetails>
          <Image fixed={data.file.childImageSharp.fixed} alt='Refillable Coffee Pods' />
          <Description>
            <Paragraph>These refillable and reusable coffee pods allow you to combine your favourite artisan coffee with a quick and easy coffee pod machine.</Paragraph>
            <Paragraph><strong>Note:</strong> To get the best out of your pods, use high-quality ground coffee for espressos and pack it as tightly as possible.</Paragraph>
            <ul>
              <li>5 refillable and reusable pods in a set</li>
              <li>Compatible with Nespresso™ machines</li>
              <li>Easy to close and open lid</li>
            </ul>
            <LinkButton href='https://www.takealot.com/x/PLID54854934?utm_source=the-story&utm_medium=referral&utm_campaign=seller' title='Buy refillable coffee pods on Takealot' target='_blank'>Buy on takealot.com</LinkButton>
          </Description>
        </ProductDetails>
      </Container>
    </SectionContainer>
  )
}

export default Product