import React from 'react'
import YouTube from 'react-youtube-embed'

import { SectionHeading, SectionSubheading } from 'styles/typography'
import { Container, SectionContainer } from 'styles/container'

const HowToUse = () => {
  return (
    <SectionContainer altBackground id="how-to-use">
      <Container>
        <SectionHeading>How to use our refillable coffee pods</SectionHeading>
        <SectionSubheading>We'll show you how</SectionSubheading>
        <YouTube id='ElWIgkDlC8k' />
      </Container>
    </SectionContainer>
  )
}

export default HowToUse