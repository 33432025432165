import styled from 'styled-components'

const LinkButton = styled.a`
  background-color: #fe6669;
  color: #ffffff;
  display: inline-block;
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 3;
  padding: 0 2em;
  text-decoration: none;
  
  &:hover {
    background-color: #ff585b;
    color: #ffffff;
  }
`

export default LinkButton
