import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Product from 'components/product'
import HowToUse from 'components/how-to-use'
import ContactUs from 'components/contact-us'
import Hero from 'components/hero'

const IndexPage = () => (
  <Layout>
    <Seo title='Refillable Coffee Pods' description='Nespresso™ compatible refillable coffee pods' keywords={[`coffee`, `coffee pods`, `coffee capsules`]} />
    <Hero />
    <Product />
    <HowToUse />
    <ContactUs />
  </Layout>
)

export default IndexPage
