import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { breakpoints } from 'styles/breakpoints'

export const StyledBackgroundImage = styled(BackgroundImage)`
  align-items: center;
  background-size: cover;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 30vh;
  justify-content: center;
  margin: 0 auto;
  max-width: 1280px;
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    height: 40vh;
  }
`

export const Subtitle = styled.h2`
  font-size: 2rem;
  font-weight: 400;
  margin: 0 0 1rem;
  text-align: center;
  text-shadow: 1px 1px 1rem #000000;
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    font-size: 3rem;
  }
`

export const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
  text-shadow: 1px 1px 1rem #000000;
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    font-size: 5.5rem;
  }
`
