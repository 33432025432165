import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { StyledBackgroundImage, Subtitle, Title } from './styles'

const Hero = () => {
  const data = useStaticQuery(graphql`
      query HeroQuery {
          file(relativePath: { eq: "hero.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1280 quality: 92) {
                      ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
      }
  `)

  return (
    <StyledBackgroundImage
      fluid={data.file.childImageSharp.fluid}
    >
      <Title>It starts with a bean...</Title>
      <Subtitle>...then it turns into joy</Subtitle>
    </StyledBackgroundImage>
  )
}

export default Hero