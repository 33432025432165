import styled from 'styled-components'
import Img from 'gatsby-image'
import { breakpoints } from 'styles/breakpoints'

export const Description = styled.div`
  padding: 2rem 0;
  text-align: center;
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    flex-basis: 0;
    flex-grow: 1;
    padding: 0 4rem;
    text-align: left;
    width: 50%;
  }
`

export const Image = styled(Img)`
  max-width: 100%;
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    flex-basis: 0;
    flex-grow: 1;
    width: 50%;
  }
`

export const ProductDetails = styled.div`
  ul {
    margin-bottom: 3rem;
    li {
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.3;
      margin-bottom: 8px;
      text-align: left;
    }
  }
  @media screen and (min-width: ${breakpoints.phone + 1}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`
