import styled from 'styled-components'

export const SocialIcons = styled.div`
  align-items: center;
  color: #000000;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  justify-content: center;  
  a {
    margin: 1rem;
  }
`
